<template>
  <div>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="按设备编号查" :value="filter.devInfo.value" is-link @click="filter.devInfo.showPicker = true"/>
           <van-cell title="请选择开始时间" :value="filter.time.startVal" is-link @click="filter.time.startShow = true"/>
           <van-cell title="请选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="glo-content glo-magbot">
        <div v-if="listLoading"><van-loading type="spinner" vertical>加载中</van-loading></div>
        <div v-else>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAlarmList" :immediate-check="false">
                <div v-if="infoList.length == 0">
                    <van-empty description="数据列表为空" />
                </div>
                <div v-else>
                    <div v-for="(item,index) in infoList" :key="index" class="block" @click="previewImg(item.filePath)">
                      <div class="top">
                        <div class="thumb">
                            <van-image
                              radius="5"
                              class="thumb-img"
                              fit="cover"
                              :src="item.filePath || ('@/assets/fog/logo2.png')"
                              :lazy-load="false"
                            />
                        </div>
                        <div class="info">
                          <div>能见度等级：<span class="name">{{item.envLevel == 2 ? 
                                '良' : item.envLevel == 3 ? 
                                '一般' : item.envLevel == 4 ? 
                                '较差' : item.envLevel == 5 ? 
                                '差' : item.envLevel == 6 ?
                                '极差' :'优'}}</span></div>
                          <div>位置：<span class="name">{{item.location == 'NULL' ? '无' : item.location}}</span></div>
                        </div>
                      </div> 
                      <div class="footer">
                        <div>{{item.devCode}}</div>
                        <div>{{item.uploadTime}}</div>
                      </div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
    <!-- 筛选区域 -->
    <van-popup v-model="filter.devInfo.showPicker" round position="bottom">
        <van-picker show-toolbar :columns="filter.devInfo.columns" @cancel="filter.devInfo.showPicker = false" @confirm="areaConfirm" />
    </van-popup>
    <!-- 筛选时间段 -->
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.time.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.start"
        type="datetime"
        title="选择开始时间"
        @confirm="confromStartTime"
        @cancel="filter.time.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.time.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.time.end"
        type="datetime"
        title="选择结束时间"
        @confirm="confromEndTime"
        @cancel="filter.time.endShow = false"
      />
    </van-popup>
    <tabbar :parent="alarmtotal" />
  </div>
</template>
<script>
import tabbar from '@/components/fog/tabBar.vue'
import moment from "moment";
import { ImagePreview } from 'vant';

export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      activeName: '',
      infoList:[],
      filter:{
        devInfo:{
          devCode:'',
          value:'',
          showPicker:false,
          columns:[]
        },
        time:{
          start:new Date(),
          end:new Date(),
          startVal:'',
          endVal:'',
          startShow:false,
          endShow:false,
        }
      },
      bridgeCode:'',
      pageNum:1,
      pageSize:10,
      loading:false,
      finished:false,
      listLoading:true,
      alarmtotal:'',
      magbotVal:'',
      projectCode:'',
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 确认开始时间
    confromStartTime(val){
      this.filter.time.startVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
      this.filter.time.startShow = false;
    },
    // 确认结束时间
    confromEndTime(val){
      this.filter.time.endVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
      this.filter.time.endShow = false;
    },
    // 筛选监测区域确认事件
    areaConfirm(v){
      this.filter.devInfo.showPicker = false;
      this.filter.devInfo.value = v.text;
      this.filter.devInfo.devCode = v.devCode;
    },
    // 重置
    resetData(){
      this.filter.devInfo.value = '';
      this.filter.devInfo.devCode = '';
      this.filter.time.startVal = '';
      this.filter.time.endVal = '';
      this.$refs.time.reset();
    },
    // 筛选
    filterData(){
      this.listLoading = true;
      this.finished = false;
      this.activeName = '';
      this.infoList = [];
      this.pageNum = 1;
      this.getAlarmList();
    },
    // 获取设备列表
    devList(){
      this.$api.FOG.getAllDev({projectCode:this.projectCode}).then( d =>{
        this.filter.devInfo.columns = d;
        this.filter.devInfo.columns.forEach( e =>{
          e.text = e.devName;
        })
      })
    },
    // 加载告警信息列表
    getAlarmList(){
      const data = {
        projectCode:this.projectCode,
        devCode:this.filter.devInfo.devCode,
        beginTime:this.filter.time.startVal ? this.filter.time.startVal : void 0,
        endTime:this.filter.time.endVal ? this.filter.time.endVal : void 0
      }
      this.$api.FOG.fogMonitorDevstaPage(this.pageNum,this.pageSize,data).then( d =>{
        if(Array.isArray(d)){
            this.listLoading = false;
            this.loading = false;
            this.infoList =this.infoList.concat(d);
            this.pageSize > d.length ? this.finished = true : void 0;
            this.pageNum ++;
        }
      })
    },
    previewImg(url){
      ImagePreview([url]);
    }
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem("projectCode");
    this.getAlarmList();
    this.devList();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.block{
    border: 1px solid #dedede;
    background-color: #10aec2;
    text-align: left;font-size: 0.8rem;padding: 2%;border-radius: 10px;color: #fff;margin-bottom: 3%;
    box-shadow: 1px 1px #dedede;
    p{margin: 0;padding-bottom: 2%;color: #fff;}
    .title{
      font-size: .95rem;font-weight: bold;text-align: center;padding: 2%;border-bottom: 1px solid #fff;color: #ff6464;
      .fa{position: absolute;top: 5%;right: 2%;}
    }
    .footer{display: flex;justify-content: space-between;border-top: 1px solid #bfbfbfa6;padding: 2% 0;}
    .top{
      display: flex;
      align-items: center;
      padding: 10px;
      .thumb{
        width:20%;
        padding-top: 5px;
        .thumb-img{
          width:50px;
          height: 50px;
        }
      }
      .info{
        width:80%;
        color:#eee;
        div{
          padding: 4px 0;
        }
        .name{
          font-weight: bolder;
          color: #fff;
        }
      }
    }
}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
</style>